<template>
  <div class="grid">
    <Toast />
    <Toolbar class="col-12" style="border: 0px; padding: 0rem 0rem 0rem 0.5rem">
      <template #start>
        <Button :label="$t('button.new')" icon="pi pi-file" @click="onNew($event)" />
        <i class="mr-2" />
        <Button v-if="receipt.id" :label="$t('button.edit')" icon="pi pi-pencil" @click="onEdit($event)" />
        <i class="mr-2" />
        <Button v-if="receipt.id" :label="$t('button.preview')" icon="pi pi-eye" @click="onPreview()" />
        <i class="mr-2" />
        <!-- <Button
          v-if="receipt.id"
          :label="$t('button.print')"
          icon="pi pi-print"
          @click="onPrint()"
        /> -->
      </template>
    </Toolbar>
    <div id="receipt_form" class="p-inputtext-sm md:col-6">
      <div class="card p-fluid">
        <div class="grid field" style="margin-top: 0px">
          <label for="agentCode" class="md:col-3" style="font-weight: bold;">{{
            $t("partner.name") }}</label>
          <div class="md:col-9" style="color: red; font-weight: bold">
            {{ receipt.agentCode }}
          </div>
        </div>
        <div class="grid field" style="margin-top: 0px">
          <label for="shipmentCodes" class="md:col-3" style="font-weight: bold;">{{
            $t("receipt.shipmentCodes") }}</label>
          <div class="md:col-9" style="color: red; font-weight: bold">
            {{ receipt.shipmentCodes }}
          </div>
        </div>
        <div class="grid field" style="margin-top: 0px">
          <label for="partnerShipmentCode" class="md:col-3" style="font-weight: bold;">{{
            $t("receipt.partnerShipmentCode") }}</label>
          <div id="partnerShipmentCode" class="md:col-3" style="color: red; font-weight: bold;">
            {{ receipt.shipmentDto?.referenceCode }}
          </div>
        </div>
        <div class="grid field" style="margin-top: 0px">
          <label for="receiptCode" class="md:col-3" style="font-weight: bold;">{{
            $t("receipt.receiptCode") }}</label>
          <div class="md:col-9" style="color: red; font-weight: bold">
            {{ receipt.receiptCode }}
          </div>
        </div>
        <div class="grid field" style="margin-top: 0px">
          <label for="referenceCode" class="md:col-3" style="font-weight: bold;">{{
            $t("receipt.referenceCode") }}</label>
          <div class="md:col-9" style="color: red; font-weight: bold">
            {{ receipt.referenceCode }}
          </div>
        </div>
      </div>
      <div class="card p-fluid" style="margin-top: 10px">
        <h5 style="font-weight: bold">{{ $t("receipt.recipient") }}</h5>
        <div class="grid field">
          <label for="recipient" class="mb-2 md:col-3 md:mb-0">{{
            $t("receipt.recipientName")
            }}</label>
          <div class="md:col-8 fieldValue name">{{
            receipt.recipientName
            }}</div>
        </div>
        <div class="grid field">
          <label for="recipientphone" class="mb-2 md:col-3 md:mb-0">{{
            $t("receipt.recipientPhone")
            }}</label>
          <div class="md:col-8 fieldValue phone">{{
            receipt.recipientPhone
            }}</div>
        </div>
        <div class="grid field">
          <label for="receiver_province" class="mb-2 md:col-3 md:mb-0">{{
            $t("receipt.recipientProvince")
            }}</label>
          <div class="md:col-8 fieldValue">{{
            receipt.recipientProvince
            }}</div>
        </div>
        <div class="grid field">
          <label for="recipient_district" class="mb-2 md:col-3 md:mb-0">{{
            $t("receipt.recipientDistrict")
            }}</label>
          <div class="md:col-8 fieldValue">{{
            receipt.recipientDistrict
            }}</div>
        </div>
        <div class="grid field">
          <label for="recipient_ward" class="mb-2 md:col-3 md:mb-0">{{
            $t("receipt.recipientWard")
            }}</label>
          <div class="md:col-8 fieldValue">{{ receipt.recipientWard }}</div>
        </div>
        <div class="grid field">
          <label for="recipient_address" class="mb-2 md:col-3 md:mb-0">{{
            $t("receipt.recipientAddress")
            }}</label>
          <div class="md:col-8 fieldValue">{{
            receipt.recipientAddress
            }}</div>
        </div>
      </div> 
    </div>
    <div class="md:col-6 mt-0">
      <div class="card p-fluid">
        <div class="grid field">
          <label for="service" class="mb-2 md:col-3 md:mb-0">{{
            $t("receipt.service")
            }}</label>
          <div class="md:col-8 fieldValue">{{
            formatDelivery(receipt.deliveryType)
            }}</div>
        </div>
        <div class="grid field">
          <label for="packageNumber" class="mb-2 md:col-3 md:mb-0">{{
            $t("receipt.packageNumber")
            }}</label>
          <div class="md:col-8 fieldValue">{{ receipt.packageNumber }}</div>
        </div>
        <div class="grid field">
          <label for="weight" class="mb-2 md:col-3 md:mb-0">{{
            $t("receipt.weightLbsKg")
            }}</label>
          <div class="md:col-8 fieldValue">{{ receipt.weight }} Lbs/{{ weightKg }} Kg</div>
        </div>
        <div class="grid field">
          <label for="type" class="mb-2 md:col-3 md:mb-0">{{
            $t("receipt.type")
            }}</label>
          <div class="md:col-8 fieldValue">{{  receipt.type ? $t("packageItem.types." + receipt.type) : "" }}</div>
        </div>
        <div class="grid field">
          <label for="cod" class="mb-2 md:col-3 md:mb-0">{{
            $t("receipt.cod")
            }}</label>
          <!-- <div class="md:col-1">
            <Checkbox id="cod" ref="cod" binary v-model="receipt.cod" disabled/>
          </div> -->
          <div class="md:col-8 fieldValue">{{ formatter.formatNumber(receipt.codAmount) }} {{ $t("unit." + (receipt.codCurrency || "empty")) }}</div>
        </div>
        <div class="grid field">
          <label for="agentNote" class="mb-2 md:col-3 md:mb-0">{{
            $t("receipt.note")
            }}</label>
          <div class="md:col-8 fieldValue">{{ receipt.note }}</div>
        </div>
        <!-- <div class="grid field">
          <label for="oversize" class="mb-2 md:col-3 md:mb-0">{{
            $t("receipt.oversize_fee")
            }}</label>
          <div class="md:col-8 fieldValue">${{ receipt.overSizeFee }}</div>
        </div>
        <div class="grid field">
          <label for="remotefee" class="mb-2 md:col-3 md:mb-0">{{
            $t("receipt.remote_fee")
            }}</label>
          <div class="md:col-8 fieldValue">${{ receipt.remoteFee }}</div>
        </div>
        <div class="grid field">
          <label for="insurance" class="mb-2 md:col-3 md:mb-0">{{
            $t("receipt.insurance_fee")
          }}</label>
          <div class="md:col-8 fieldValue">${{ receipt.insuranceFee }}</div>
        </div>
        <div class="grid field">
          <label for="insurance" class="mb-2 md:col-3 md:mb-0">{{
            $t("receipt.extra_fee")
            }}</label>
          <div class="md:col-8 fieldValue">${{ receipt.extraFee }}</div>
        </div> 
        <div class="grid field">
          <label for="totalfee" class="md:col-3" style="font-weight:bold;margin-top:10px;">{{$t('receipt.total')}}</label>
          <div class="mt-1 md:col-8 flex" >
            <InputNumber id="totalFee" v-model="totalFee" mode="decimal" :min="0" disabled 
              :inputStyle="{color:'red','font-size':'18px', 'font-weight':'bold'}"/>
            <span class="p-inputgroup-addon">$</span>
          </div>
        </div> -->
        <!-- <div class="grid field">
          <label for="payment_status" class="mb-2 md:col-3 md:mb-0">{{
            $t("receipt.paymentStatus")
            }}</label>
          <label class="md:col-4 fieldValue">{{ receipt.paymentStatus }}/ {{ receipt.paymentMethod }}</label>
        </div>
        <div class="grid field">
          <label for="allow_delivery" class="mb-2 md:col-3 md:mb-0">{{
            $t("receipt.allowDelivery")
            }}</label>
          <label class="md:col-8 fieldValue">{{
            receipt.allowDelivery == "1" ? "Yes" : "No"
            }}</label>
        </div> -->
      </div>  
      <div class="card p-fluid">
        <h5 style="font-weight: bold">{{ $t("receipt.sender") }}</h5>
        <div class="grid field">
          <label for="sendername" class="mb-2 md:col-3 md:mb-0">{{
            $t("receipt.senderName")
            }}</label>
          <div class="md:col-8 fieldValue name">{{
            receipt.senderName
            }}</div>
        </div>
        <div class="grid field">
          <label for="senderphone" class="mb-2 md:col-3 md:mb-0">{{
            $t("receipt.senderPhone")
            }}</label>
          <div class="md:col-8 fieldValue phone">{{
            receipt.senderPhone
            }}</div>
        </div>
        <div class="grid field">
          <label for="senderState" class="mb-2 md:col-3 md:mb-0">{{
            $t("receipt.senderState")
            }}</label>
          <div class="md:col-8 fieldValue">{{ receipt.senderState }}</div>
        </div>
        <div class="grid field">
          <label for="senderCounty" class="mb-2 md:col-3 md:mb-0">{{
            $t("receipt.senderCounty")
            }}</label>
          <div class="md:col-8 fieldValue">{{ receipt.senderCounty }}</div>
        </div>
        <div class="grid field">
          <label for="sendercity" class="mb-2 md:col-3 md:mb-0">{{
            $t("receipt.senderCity")
            }}</label>
          <div class="md:col-8 fieldValue">{{ receipt.senderCity }}</div>
        </div>
        <div class="grid field">
          <label for="senderAddress" class="mb-2 md:col-3 md:mb-0">{{
            $t("receipt.senderAddress")
            }}</label>
          <div class="md:col-8 fieldValue">{{ receipt.senderAddress }}</div>
        </div>
        <div class="grid field">
          <label for="delivery_note" class="mb-2 md:col-3 md:mb-0">{{
            $t("receipt.delivery_note")
            }}</label>
          <div class="md:col-8 fieldValue">{{ receipt.deliveryNote }}</div>
        </div>
      </div>  
    </div>

    <Toolbar class="col-12" style="border: 0px; padding: 0rem 0rem 0rem 0.5rem">
      <template #start style="border: 0px; padding-right: 5rem">
        <Button :label="$t('button.new')" icon="pi pi-file" @click="onNew($event)" />
        <i class="mr-2" />
        <Button v-if="receipt.id" :label="$t('button.edit')" icon="pi pi-pencil" @click="onEdit($event)" />
        <i class="mr-2" />
        <Button v-if="receipt.id" :label="$t('button.preview')" icon="pi pi-eye" @click="onPreview()" />
      </template>
    </Toolbar>
    <div class="col-12">
      <TabView v-if="showPackageTable">
        <TabPanel :header="$t('receipt.manifests')">
          <ReceiptManifestEdit :receipt="receipt" @changeTax="onChangeManifestTax" @changeExtraFee="onChangeExtraFee"
            @saveSuccess="onSaveManifestSuccess" @deleteSuccess="onDeleteManifestSuccess" />
        </TabPanel>
        <TabPanel :header="$t('receipt.packages')">
          <div class="grid field" style="margin-top: 0px">
            <label for="lastPackageEmsCode" class="md:col-3" style="font-weight: bold; margin-top: 10px">{{
              $t("package.lastEmsCode") }}</label>
            <div id="lastPackageEmsCode" class="md:col-3" style="color: red; font-size: 24px">
              {{ lastPackageEmsCode }}
            </div>
          </div>
          <CrudTable :entities="receipt.packageDtos" :meta="metaPackageTable" @cell-edit-complete="onCellEditComplete"
            @tableAction="onTableAction" @itemAction="onItemAction" />
        </TabPanel>
      </TabView>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import CrudTable from "@/pages/common/CrudTable";
import ReceiptManifestEdit from "./ReceiptManifestEdit";
import ReceiptService from "@/service/ReceiptService";
import PackageService from "@/service/PackageService";
import { isPositiveNumber } from "@/utils/common";
import { Consts } from "@/utils/consts";
import formatter from "@/utils/formatter";
import form from "@/mixins/form";
import AuthService from "@/service/AuthService";
export default {
  components: { CrudTable, ReceiptManifestEdit },
  mixins: [form],
  data() {
    const allowDeliveryOptions = [
      { label: this.$t("receipt.allowDeliveryOptions.yes"), code: "1" },
      { label: this.$t("receipt.allowDeliveryOptions.no"), code: "0" },
    ];
    return {
      formatter: formatter,
      originalEntity: {},
      receipt: {
        type: "normal",
      },
      partnerShipmentCode: null,
      submitted: false,
      errors: {},
      weightKg: null,
      lastPackageEmsCode: "",
      serviceItems: [
        { label: this.$t("receipt.serviceOptions.door2door"), code: 1 },
        { label: this.$t("receipt.serviceOptions.pickatoffice"), code: 2 },
      ],
      selectedService: null,
      paymentStatusItems: [
        { label: this.$t("receipt.paymentStatuses.paid"), code: "paid" },
        { label: this.$t("receipt.paymentStatuses.unpaid"), code: "unpaid" },
      ],
      paymentMethodOptions: [
        { label: "Zelle", code: "zelle" },
        { label: "Venmo", code: "venmo" },
        { label: "Pay in VN", code: "paid_in_vn" },
        { label: "Cash", code: "cash" },
        { label: "Thu hộ", code: "thuho" },
      ],
      allowDeliveryOptions,
      usGeoInfos: {},
      vnGeoInfos: {},
      usStates: [],
      usCounties: [],
      usCities: [],
      vnProvinces: [],
      vnDistricts: [],
      vnWards: [],
      selectedPaymentStatus: null,
      showPackageTable: false,
      metaPackageTable: {
        entityName: "package",
        expander: false,
        editMode: "cell",
        paginator: {
          enable: true,
          rows: 10,
        },
        columns: [
          // {
          //   name: "receiptCode",
          //   headerStyle: "width:15%; min-width:10rem;",
          // },
          {
            name: "packageCode",
            headerStyle: "width:15%; min-width:10rem;",
          },
          // {
          //   name: "packageEmsCode",
          //   headerStyle: "width:15%; min-width:10rem;",
          //   editor: {
          //     enable: true,
          //   },
          // },
          {
            name: "weightLbs",
            headerStyle: "width:15%; min-width:10rem;",
            editor: {
              enable: true,
            },
          },
          {
            name: "deleted",
            label: "common.deleted",
            headerStyle: "width:15%; min-width:10rem;",
            editor: {
              enable: false,
            },
          },
          {
            name: "description",
            headerStyle: "min-width:10rem;",
            editor: {
              enable: true,
            },
          },
        ],
        tableActions: [
          /*
						{
							name: "create",
							icon: "pi pi-plus",
							style: "p-button-primary mr-2"
						},
						{
							name: "packageedit",
							icon: "pi pi-pencil",
							style: "p-button-primary mr-2"
						},
						*/
        ],
        itemActions: [
          /*
						{
							name: "edit",
							icon: "pi pi-pencil",
							style: "p-button-rounded p-button-success mr-2"
						},
						{
							name: "delete",
							icon: "pi pi-trash",
							style: "p-button-rounded p-button-warning mr-2"
						},
						{
							name: "qrcode",
							icon: "pi pi-qrcode",
							style: "p-button-rounded p-button-primary mr-2"
						}
						*/
        ],
        metaSubTable: {
          entityName: "packageItem",
          selectionMode: "multiple",
          paginator: {
            enable: false,
          },
          tableActions: [
            {
              name: "create",
              icon: "pi pi-plus",
              style: "p-button-rounded p-button-success mr-2",
            },
          ],
          columns: [
            {
              name: "name",
              headerStyle: "width:20%; min-width:10rem;",
            },
            {
              name: "quantity",
              headerStyle: "width:20%; min-width:10rem;",
            },
          ],
          itemActions: [
            {
              name: "edit",
              icon: "pi pi-pencil",
              style: "p-button-rounded p-button-success mr-2",
            },
            {
              name: "delete",
              icon: "pi pi-trash",
              style: "p-button-rounded p-button-warning mt-2",
            },
          ],
        },
      },
    };
  },
  async mounted() {
    this.$watch(
      () => this.$props.receipt,
      (params) => {
        console.log(params);
        this._init(params.id);
        //console.log('route path has changed from ' +from+' to '+to )
      }
    );
    this.$watch(
      () => this.$route.params,
      (params) => {
        this._init(params.id);
        //console.log('route path has changed from ' +from+' to '+to )
      }
    );
    this._init(this.$route.params.id);
  },
  computed: {
    showDiscount() {
      const user = AuthService.currentUser || {};
      return user.orgType == 'owner' || user.organization == 'P';
    },
    totalFeeValue() {
      let totalFee = 0;
      if (this.receipt.insuranceFee > 0) {
        totalFee += this.receipt.insuranceFee;
      }
      if (this.receipt.extraTax > 0) {
        totalFee += this.receipt.extraTax;
      }
      if (this.receipt.extraFee > 0) {
        totalFee += this.receipt.extraFee;
      }
      if (this.receipt.oversizeFee > 0) {
        totalFee += this.receipt.oversizeFee;
      }
      if (this.receipt.weight > 0 && this.receipt.unitPrice > 0) {
        totalFee += this.receipt.weight * this.receipt.unitPrice;
      }
      if (this.receipt.remoteFee > 0) {
        totalFee += this.receipt.remoteFee;
      }
      return totalFee;
    },
    totalFee() {
      let totalFee = this.totalFeeValue;
      return totalFee.toLocaleString("en-US");
    },
    finalFee() {
      let finalFee = this.totalFeeValue;
      if (this.receipt.discountAmount >= 0) {
        finalFee = finalFee - this.receipt.discountAmount;
      }
      return finalFee.toLocaleString("en-US");
    },
    senderAddress() {
      let parts = [];
      if (this.receipt.senderState) {
        parts.push(this.receipt.senderState);
      }
      if (this.receipt.senderCounty) {
        parts.push(this.receipt.senderCounty);
      }
      if (this.receipt.senderCity) {
        parts.push(this.receipt.senderCity);
      }
      if (this.receipt.senderAddress) {
        parts.push(this.receipt.senderAddress);
      }
      return parts.join(", ");
    },
    invalidSenderName() {
      return this.submitted && !this.receipt.senderName;
    },
  },
  methods: {
    async _init(receiptId) {
      if (receiptId) {
        this.showPackageTable = true;
        this.loadReceipt(receiptId);
      } else {
        // this.lastReceiptCode = await ReceiptService.getLastReceiptCode();
        this.originalEntity = {};
        this.receipt = { };
        this.showPackageTable = false;
      }
    },
    async loadReceipt(id) {
      let receipt = await ReceiptService.get(id);
      this._setReceipt(receipt);
      let lastEmsCode = await PackageService.getLastEmsPackageCode();
      this.lastPackageEmsCode = lastEmsCode.data;
    },
    _setReceipt(receipt) {
      if (receipt) {
        receipt.type = "normal";
        this.originalEntity = receipt;
        this.receipt = receipt;
        this.weightKg =
          Math.round(receipt.weight * Consts.POUND2KG * 100) / 100;
        if (receipt.id) {
          this.showPackageTable = true;
        }
        if (Array.isArray(receipt.packageItems)) {
          for (let item of receipt.packageItems) {
            if (item.type == "special") {
              receipt.type = "special";
              break;
            }
          }
        }
        if (Array.isArray(receipt.packageDtos)) {
          receipt.packageDtos.forEach(pkg => {
            if (Array.isArray(pkg.itemDtos) && (pkg.description == null || pkg.description == '')) {
              pkg.description = pkg.itemDtos.map(item => item.quantity + ' ' + item.name).join(", ");
            }
          });
        }
      }
    },
    onBack() {
      this.$router.back();
    },
    onPreview() {
      this.$router.push({
        name: "print_receipt",
        params: { id: this.receipt.id },
      });
    },
    onPrint() {
      //this.$htmlToPaper('print');
      const routeData = this.$router.resolve({
        name: "print_receipt",
        params: { id: this.receipt.id },
      });
      this.$htmlToPaper({
        url: routeData.href,
        defaultReplace: false,
      });
      //this.$htmlToCanvas({url:routeData.href})
    },
    formatDelivery(code) {
      switch (code) {
        case 1:
        case "1":
          return this.$t("receipt.serviceOptions.door2door");
        case 2:
        case "2":
          return this.$t("receipt.serviceOptions.pickatoffice");
        default:
          return "";
      }
    },
    formatPaymentMethod(method) {
      if (method == "thuho") return this.$t("receipt.thuho");
      return method;
    },
    onChangeManifestTax(value) {
      console.log(value);
      if (this.receipt) {
        this.receipt.extraTax = value; //(this.receipt.extraTax || 0) + value;
      } else {
        this.receipt = {
          extraTax: value,
        };
      }
    },
    onChangeExtraFee(value) {
      if (this.receipt) {
        this.receipt.extraFee = value; 
      } else {
        this.receipt = {
          extraFee: value,
        };
      }
    },
    onSaveManifestSuccess() {
      this.loadReceipt(this.$route.params.id);
      this.$toast.add({
        severity: "success",
        summary: "Successful",
        detail: this.$t("receipt.save_manifest_successful"),
        life: 3000,
      });
    },
    onDeleteManifestSuccess() {
      this.loadReceipt(this.$route.params.id);
      this.$toast.add({
        severity: "success",
        summary: "Successful",
        detail: this.$t("receipt.delete_manifest_successful"),
        life: 3000,
      });
    },
    onCellEditComplete(event) {
      let { data, newValue, field } = event;
      switch (field) {
        case "weightLbs":
          if (isPositiveNumber(newValue)) {
            data[field] = newValue;
            PackageService.updateField(data.id, field, newValue);
          } else {
            event.preventDefault();
          }
          break;

        default:
          if (newValue.trim().length > 0) {
            data[field] = newValue;
            PackageService.updateField(data.id, field, newValue);
          } else {
            event.preventDefault();
          }
          break;
      }
    },
    onNew() {
      this.$router.push({ name: "users_receipt_create" });
    },
    onEdit() {
      this.$router.push({
        name: "users_receipt_edit",
        params: { id: this.receipt.id },
      });
    },
    onTableAction(action) {
      if (action == "create") {
        console.log("Call packageEdit");
      } else if (action == "packageedit") {
        this.$router.push({
          name: "users_receipt_package",
          params: { receiptId: this.receipt.id },
        });
      } else if (action == "delete") {
        console.log("Call packageDelete");
      }
    },
    onItemAction(action, entity) {
      if (action == "edit") {
        console.log("Call packageEdit");
        console.log(entity);
        this.$router.push({
          name: "users_receipt_package",
          params: { receiptId: this.receipt.id, packageId: entity.id },
        });
      } else if (action == "delete") {
        console.log("Call packageDelete");
        console.log(entity);
      } else if (action == "qrcode") {
        console.log(entity);
      }
    },
    initManifestFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.narrow .p-toolbar {
  padding: 0 1rem;
  margin: -0.5rem 0;
  border: 0;
  font-weight: 600;
}
.info .grid {
  padding-top: 3px;
}
.field.grid > label {
  align-items: center;
}
.field {
  margin-bottom: 0;
}
.phone {
  color: red;
  font-size: 1.2em;
}
.name {
  font-size: 1.2em;
  color: red;
  text-transform: uppercase;
}
</style>
